import * as React from "react"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404 : Not found</title>
    </Helmet>
    <Seo title="404 : Not found"></Seo>
    <section className="section feuille-conteneur">
      <div className="feuille">
        <h2>404 : Not Found</h2>
        <p>La page que vous cherchez n'existe pas... 😔</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
